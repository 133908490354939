export function createStatisticsFromBoardings(voyageToUpdate, unassignedBoardings) {
  // parameter voyageToUpdate should be used here
  // But updating the data was too mutch work
  // for what it was worth
  const array = [];

  unassignedBoardings?.forEach(({ label, isBoardingFerry, ...boarding }) => {
    const prevValues = array.find((existing) => label.value === existing.label && existing?.type === 'boardings') || {};
    const itemIndex = array?.indexOf(prevValues);
    const boarded = (prevValues?.count || prevValues?.boarded || 0);
    const prevPassengers = (prevValues?.passengers || 0);

    const newObject = {
      label: label.value,
      type: 'boardings',
      passengers: isBoardingFerry ? prevPassengers + (boarding?.passengers || 0) : prevPassengers,
      count: isBoardingFerry ? boarded + 1 : boarded,
    };

    if (itemIndex === -1) array.push(newObject);
    else array[itemIndex] = newObject;
  });

  return array;
}

export function updateStatisticsWithSseData(voyage, payload) {
  const array = voyage?.statistics || [];

  // For subscription data in ferry status dashboard
  const entryToUpdate = voyage?.statistics?.find(
      ({ label, type }) => type === 'boardings' && label === payload?.label?.value,
  ) || [];

  const itemIndex = array.indexOf(entryToUpdate);

  const count = (entryToUpdate?.count || 0);

  const prevPassengers = (entryToUpdate?.passengers || 0);

  const newObject = {
    label: entryToUpdate?.value || payload?.label?.value || '--',
    type: 'boardings',
    passengers: payload.isBoardingFerry ?
      prevPassengers + payload?.passengers :
      prevPassengers,
    count: payload.isBoardingFerry ? count + 1 : count,
  };

  if (itemIndex === -1 || !itemIndex) array.push(newObject);
  else array[array.length > 0 ? array.length - 1 : 0] = newObject;

  return array;
}

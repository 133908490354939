import { combineReducers } from 'redux';
import { reducer as userReducer } from './slices/user';
import { reducer as companyReducer } from './slices//company';
import { reducer as harborReducer } from './slices/harbor';
import { reducer as edgeReducer } from './slices/edge';
import { reducer as ferryReducer } from './slices/ferry';
import { reducer as voyageReducer } from './slices/voyage';
import { reducer as systemReducer } from './slices/system';
import { reducer as labelReducer } from './slices/label';
import { reducer as settingReducer } from './slices/setting';
import { reducer as dockReducer } from './slices/dock';
import { reducer as changelogReducer } from './slices/changelog';
const rootReducer = combineReducers({
  user: userReducer,
  company: companyReducer,
  harbor: harborReducer,
  edge: edgeReducer,
  ferry: ferryReducer,
  voyage: voyageReducer,
  system: systemReducer,
  label: labelReducer,
  setting: settingReducer,
  dock: dockReducer,
  changelog: changelogReducer,
});

export default rootReducer;

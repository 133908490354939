import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { API } from '../../Config';
import { logout } from '../../store/slices/user';

const AuthGuard = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authRoutine = useCallback(async () => {
    // validate token
    const token = localStorage.getItem('token');

    await axios.get(`${API}/auth/verifyToken/?token=${token}`).then((res) => {
      if (!res.data?.isValid || res.statusText >= 400) {
        dispatch(logout());
        navigate('/auth/login', { replace: true });
      }
    });
  }, [user]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    authRoutine();
  }, [user]);

  return <>{children}</>;
};

export default AuthGuard;

import { Button, Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

export function EulaDialog() {
  const [eulaOpen, setEulaOpen] = useState(false);

  const isEulaAccepted = async () => {
    const eulaAccepted = JSON.parse(localStorage.getItem('eulaAccepted'));

    if (!eulaAccepted) {
      setEulaOpen(true);
    }
  };

  useEffect(() => {
    isEulaAccepted();
  }, []);

  const handleAgree = async () => {
    await localStorage.setItem('eulaAccepted', JSON.stringify(true));
    setEulaOpen(false);
  };

  return (
    <Dialog
      open={eulaOpen}
      keepMounted
      scroll="paper"
      maxWidth="sm"
      sx={{
        m: 2,
        maxHeight: 400,
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            my: 1,
          }}
        >
          GDPR and Privacy Policy while viewing our beta
        </Typography>
        <Typography
          variant="body1"
          sx={{
            my: 1,
          }}
        >
          Bilder som er tilgjengelig i denne løsningen inneholder
          personinformasjon i form av identifiserbare personer og kjøretøy.
          Bildene og informasjon som kan sees i løsningen er konfidensiell og
          skal utelukkende benyttes til å evaluere nøyaktigheten av systemet. I
          system i drift vil personinformasjon være sladdet.
        </Typography>
        <Button
          variant="contained"
          fullWidth
          onClick={handleAgree}
          sx={{ mt: 1 }}
        >
          I Agree
        </Button>
      </Box>
    </Dialog>
  );
}

import { MenuOutlined } from '@mui/icons-material';
import { AppBar, Box, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { toggleSidebar } from '../store/slices/system';

const Nothing = () => <></>;

const PageWrapper = ({
  title = '',
  SubTitle=Nothing,
  actions = null,
  children,
}) => {
  const { isMobile } = useWindowDimensions();
  const dispatch = useDispatch();

  // AppBar zIndex prevents sidebar button being covered by sidebar. Quick workaround.
  // This happens seemingly at random and is likely related to sidebar keepmounted prop.
  return (
    <Stack width="100%" flexDirection="column">
      {isMobile && (
        <AppBar sx={{ zIndex: 1201 }} position="sticky">
          <Toolbar variant="dense">
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
            >
              <MenuOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Stack flexDirection="column" flex={1} overflow="auto" p={2}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
          marginBottom={1}
        >
          <Helmet>
            <title>{title} | Counting Hero</title>
          </Helmet>
          <Stack flexDirection="column">
            <Typography variant="h3">{title}</Typography>
            {SubTitle && SubTitle}
          </Stack>
          <Box>{actions}</Box>
        </Stack>
        <Box py={1}>{children}</Box>
      </Stack>
    </Stack>
  );
};

export default PageWrapper;

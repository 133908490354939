export default function CountingHeroLogo({ width='100px' }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={width}
      viewBox="110 182 300 150"
      enableBackground="new 0 0 512 512"
      xmlSpace="preserve"
    >
      <defs>
        <linearGradient id="mainGradient" x1="0%" x2="100%" y1="0%" y2="40%">
          <stop offset="15%" stopColor="#1B69AC" />
          <stop offset="100%" stopColor="#FFB732" />
        </linearGradient>

        <linearGradient id="leftEyeGradient" x1="0%" x2="100%" y1="0%" y2="40%">
          <stop offset="20%" stopColor="#417596" />
          <stop offset="90%" stopColor="#75877C" />
        </linearGradient>

        <linearGradient id="rightEyeGradient" x1="0%" x2="100%" y1="0%" y2="40%">
          <stop offset="20%" stopColor="#A89A60" />
          <stop offset="90%" stopColor="#E1AC40" />
        </linearGradient>
      </defs>
      <path
        fill="url(#mainGradient)"
        opacity="1.000000"
        stroke="none"
        d="
          M316.999573,324.939880
          C273.194763,324.932281 229.889847,324.915985 186.584915,324.923248
          C172.499756,324.925598 158.638641,323.872955 145.972015,316.803558
          C125.175514,305.196808 113.227905,287.684387 110.598228,263.917023
          C106.454430,226.464813 133.478027,195.180542 166.712265,190.242676
          C168.515732,189.974716 170.341827,189.697922 172.157562,189.696762
          C227.454269,189.661850 282.751007,189.700974 338.047668,189.638351
          C366.629272,189.605988 394.333466,209.474335 402.003143,240.298492
          C411.796814,279.658875 386.129517,318.231842 345.921844,324.015564
          C336.558289,325.362518 326.993073,325.165161 316.999573,324.939880
          M129.664352,232.141922
          C124.520828,242.702652 122.501755,253.581543 124.156868,265.465363
          C127.428459,288.955658 149.499130,309.907532 173.069153,310.707916
          C184.219620,311.086548 195.387970,311.020325 206.548172,311.015930
          C250.530273,310.998566 294.512939,311.045166 338.494263,310.833984
          C371.031311,310.677795 394.749451,277.948883 389.884308,249.421341
          C386.275177,228.258331 371.126038,210.498734 350.496521,204.809097
          C345.095947,203.319641 339.661407,203.094757 334.185272,203.088608
          C282.704956,203.030807 231.224335,203.113770 179.744232,202.985123
          C157.577286,202.929718 141.107895,212.430084 129.664352,232.141922z
        "
      />
      <path
        fill="url(#rightEyeGradient)"
        opacity="1.000000"
        stroke="none"
        d="
          M331.990784,241.732330
          C339.535614,245.324265 345.446472,250.348206 350.375610,256.554108
          C353.276001,260.205780 353.327728,264.422394 350.623260,266.910889
          C347.704193,269.596863 343.365967,269.157806 340.034637,265.624542
          C336.947235,262.349976 333.840790,259.132080 330.124878,256.544617
          C322.039062,250.914185 313.765656,250.633911 305.340210,255.664505
          C300.886108,258.323944 297.050934,261.752350 293.433136,265.467865
          C289.865509,269.131836 285.763367,269.607208 282.879944,266.951935
          C279.871735,264.181702 279.918884,260.149902 283.389496,256.372498
          C293.268280,245.620422 304.430939,237.490829 320.133514,238.617752
          C324.203979,238.909882 327.941742,240.058304 331.990784,241.732330z
        "
      />
      <path
        fill="url(#leftEyeGradient)"
        opacity="1.000000"
        stroke="none"
        d="
          M210.569458,241.085052
          C218.574997,244.572067 224.647186,249.946228 230.046951,256.141205
          C233.480728,260.080688 233.676834,264.048737 230.789780,266.814941
          C227.820740,269.659698 223.395279,269.064362 219.892624,265.231903
          C217.421082,262.527618 214.942764,259.866669 211.957367,257.690430
          C201.669220,250.190826 193.053345,250.120056 182.620270,257.448792
          C179.056610,259.952087 175.990997,262.979401 172.940933,266.048798
          C169.946365,269.062347 166.408691,269.684692 163.126541,266.948517
          C159.718460,264.107391 159.948822,260.421570 162.748535,257.231567
          C172.628998,245.973846 183.984833,237.622009 200.061111,238.608688
          C203.626541,238.827515 206.879059,239.935715 210.569458,241.085052z
        "
      />
    </svg>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { startOfDay } from 'date-fns';
import { API } from '../../Config';
import { createStatisticsFromBoardings, updateStatisticsWithSseData } from '../helpers';

const queryOptions = {
  onError: null,
  onFinish: null,
};

const dashboardVoyageInitalState = {
  ferryId: null,
  statistics: [],
  boardedObjects: {},
  boardings: [],
  passengers: 0,
  id: null,
};

const initialState = {
  voyage: null,
  dashboardVoyage: dashboardVoyageInitalState,
  voyages: [],
};

const slice = createSlice({
  name: 'voyage',
  initialState,
  reducers: {
    setVoyage(state, action) {
      state.voyage = action.payload;
    },
    getVoyages(state, action) {
      state.voyages = action.payload;
    },
    setDashboardVoyage(state, action) {
      state.dashboardVoyage = {
        ...state.dashboardVoyageInitalState,
        ...action.payload,
      };
    },
    updateDashboardVoyage(state, action) {
      const newState = state.dashboardVoyage.id ? {
        boardings: [],
        id: null,
        statistics: [],
      } : {};

      state.dashboardVoyage = {
        ...state.dashboardVoyage,
        ...action.payload,
        ...newState,
      };

      state.dashboardVoyage.statistics = createStatisticsFromBoardings(state.dashboardVoyage, action.payload.boardings);
    },
    updatePreviousVoyageBoardings(state, action) {
      state.voyages[0].statistics = [
        ...state.voyages?.[0]?.statistics,
        {
          type: 'leftFromPrevious',
          ...action.payload,
        },
      ];
    },
    updateDashboardVoyageWithSse(state, action) {
      const doesExist = state.dashboardVoyage?.boardings?.find(({ id }) => id === action.payload?.id);

      if (!doesExist) {
        state.dashboardVoyage = {
          ...dashboardVoyageInitalState,
          ...state.dashboardVoyage,
          boardings: [
            ...state.dashboardVoyage.boardings,
            action.payload,
          ],
          statistics: updateStatisticsWithSseData(state.dashboardVoyage, action.payload),
        };
      };
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

export const { reducer } = slice;

export const getVoyagesByCompany = (query) => async (dispatch) => {
  try {
    const res = await axios.get(
        `${API}/voyage/harbor/${query.harborId}?take=${query.take}`,
    );

    dispatch(slice.actions.getVoyages(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const updateVoyageWithSse = (subscriptionData) => async (dispatch) => {
  console.log(subscriptionData?.isBoardingFerry);
  if (subscriptionData?.isBoardingFerry) {
    dispatch(slice.actions.updateDashboardVoyageWithSse(subscriptionData));
  } else {
    dispatch(slice.actions.updatePreviousVoyageBoardings(subscriptionData));
  }
};

export const setDashboardVoyage = (voyage) => async (dispatch) => {
  dispatch(slice.actions.setDashboardVoyage(voyage));
};

export const updateDashboardVoyage = (dockId, ferryId) => async (dispatch) => {
  const today = startOfDay(new Date());

  await axios
      .get(
          `${API}/boarding/unassigned/dock/${dockId}/after/${today.toISOString()}`,
      )
      .then(({ data }) => {
        dispatch(
            slice.actions.updateDashboardVoyage({
              ferryId: ferryId,
              boardings: data,
            }),
        );
      })
      .catch((error) => console.error(error));
};

export const setVoyage = (voyage) => async (dispatch) => {
  dispatch(slice.actions.setVoyage(voyage));
};

export const getVoyagesByFerry = (query, options=queryOptions) => async (dispatch) => {
  if (!query?.ferryId) return;

  const { onError, onFinish } = options;

  try {
    // uses app endpoint since it doesnt include boardings
    // Which makes it significantly faster
    // remove '/app' to go back to old endpoint
    const res = await axios.get(`${API}/voyage/app/ferry/${query.ferryId}?take=${query.take}`);

    dispatch(slice.actions.getVoyages(res.data));
    onFinish?.(res);
  } catch (error) {
    onError?.(error);
    console.error(error);
  }
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';

const initialState = {
  settings: [],
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    getSettings(state, action) {
      state.settings = action.payload;
    },
    updateSetting(state, action) {
      const index = state.settings.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.settings[index] = action.payload;
    },
    addSetting(state, action) {
      state.settings = [...state.settings, action.payload];
    },
    addManySettings(state, action) {
      const settingsToAdd = [];

      const settingsByName = new Map(state?.settings?.map((entry) => [entry?.name, entry]));
      action?.payload?.forEach((newSetting) => {
        if (!settingsByName.has(newSetting?.name)) {
          settingsToAdd.push(newSetting);
        }
      });

      state.settings = state?.settings?.concat(action.payload);
    },
  },
});

export const { reducer } = slice;

export const getManySettings = ({ value='', type='', name='' }) => async (dispatch) => {
  try {
    if (`${value}${type}${name}`.length === 0) return; // no arguments was provided, no nothing can be returned

    const res = await axios.get(
        `${API}/setting/many?value=${value}&type=${type}&name=${name}`,
    );

    dispatch(slice.actions.addManySettings(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const getSettings = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API}/setting`);

    dispatch(slice.actions.getSettings(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const updateSetting = (setting, updatedValue) => async (dispatch) => {
  try {
    const res = await axios.patch(`${API}/setting/${setting.id}`, {
      value: updatedValue,
    });

    dispatch(slice.actions.updateSetting(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const addSetting = (setting) => async (dispatch) => {
  try {
    const res = await axios.post(`${API}/setting`, setting);

    dispatch(slice.actions.addSetting(res.data));
  } catch (error) {
    console.error(error);
  }
};

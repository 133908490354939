import { KeyboardArrowDownRounded, KeyboardArrowUpRounded, LogoutOutlined } from '@mui/icons-material';
import { Box, Collapse, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getCompanyByUser } from '../../store/slices/company';
import { toggleSidebar } from '../../store/slices/system';
import { logout } from '../../store/slices/user';
import AppInit from '../AppInit';
import Logo from '../Logo';
import { EulaDialog } from '../eula';
import { UserCard } from './UserCard';
import { menuItems } from './menuItems';

export function Sidebar() {
  const user = useSelector((state) => state.user.user);
  const role = useSelector((state) => state.user.role);
  const company = useSelector((state) => state.company.company);
  const isSidebarOpen = useSelector((state) => state.system.isSidebarOpen);
  const [fetched, setFetched] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    if (isMobile && isSidebarOpen) dispatch(toggleSidebar(false));
  }, [isMobile]);

  const ResetButton = () => (
    <Tooltip arrow title="Back to login">
      <IconButton onClick={() => dispatch(logout())}>
        <LogoutOutlined color="white" />
      </IconButton>
    </Tooltip>
  );

  const fetchCompany = async () => {
    dispatch(getCompanyByUser()).then((res) => {
      if (!res?.data?.id) {
        enqueueSnackbar({
          preventDuplicate: true,
          variant: 'error',
          key: 'fetching-company-error-144bba',
          message: 'something went from when fetching company',
          action: ResetButton,
        });
      }
      setFetched(true);
    });
  };

  useEffect(() => {
    if (!fetched && !company) {
      fetchCompany();
    }
  }, []);

  const items = menuItems?.[role?.role] || [];
  const { selected, selectedChildItem } = useMemo(() => {
    let selected = '';
    let selectedChildItem = '';

    items?.forEach(({ link, name, ...rest }) => {
      if (pathname === link || pathname.includes(link)) {
        selected = name;
      } else if (rest?.items) {
        rest?.items.forEach((child) => {
          if (pathname === child?.link) {
            selectedChildItem = child?.name;
            selected = name;
          }
        });
      }
    });

    return { selected, selectedChildItem };
  }, [pathname]);

  const [openMenu, setOpenMenu] = useState(selected || '');

  const handleSetOpenMenu = (name, closeSidebar=true) => {
    setOpenMenu(openMenu === name ? '' : name);
    if (isMobile && closeSidebar) dispatch(toggleSidebar(false));
  };

  const onMenuItemClick = (to, title, closeSidebar=true) => {
    if (to) navigate(to);
    handleSetOpenMenu(title, closeSidebar);
  };

  if (!user || !company) return <AppInit />;
  return (
    <Stack
      overflow="auto"
      flexDirection="row"
      flex={1}
      height="100vh"
      width="100%"
    >
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        onClose={() => dispatch(toggleSidebar())}
        open={isMobile ? isSidebarOpen : true}
        anchor="left"
        sx={{
          'width': theme.sidebar.width,
          '& .MuiDrawer-paper': {
            width: theme.sidebar.width,
            paddingTop: isMobile ? 6 : 0,
            background: theme.sidebar.background,
          },
        }}
      >
        <Logo
          BoxSx={{
            display: 'flex',
            paddingTop: 1,
            cursor: 'pointer',
            justifyContent: 'center',
            width: '100%',
          }}
          onClick={() => navigate('/dashboard/company/ferry')}
          mode="icon"
          height="auto"
          width="90px"
        />
        <Stack flexDirection="column">
          <UserCard
            openMenu={openMenu}
            onProfileCardClick={(value) => handleSetOpenMenu(value, false)}
            onListItemClick={() =>
              onMenuItemClick(`profile/${user?.id}`, user?.email)
            }
          />
          <List sx={{ display: 'flex', flexDirection: 'column' }}>
            {items?.map(({ name, items = [], Icon, link, ...item }) => (
              <Fragment key={name}>
                {item?.heading && (
                  <Typography
                    key={`${item?.heading}-text`}
                    paddingLeft={2}
                    paddingTop={1}
                    variant="caption"
                    color={theme.sidebar.headingAndIconColor}
                    fontWeight="400"
                  >
                    {item?.heading}
                  </Typography>
                )}
                <MenuItem
                  isCollapsible={items?.length > 0}
                  key={name}
                  isSelected={name === selected}
                  selectedChildItem={selectedChildItem}
                  title={name}
                  Icon={Icon}
                  items={items}
                  isOpen={openMenu === name}
                  onClick={() =>
                    onMenuItemClick(link, name, items?.length === 0)
                  }
                  onMenuItemChildClick={(childLink) =>
                    onMenuItemClick(childLink, name)
                  }
                />
              </Fragment>
            ))}
          </List>
        </Stack>
      </Drawer>
      <EulaDialog />
      <Outlet />
    </Stack>
  );
}

const MenuItem = ({
  isCollapsible=false,
  title,
  isSelected,
  selectedChildItem='',
  Icon,
  onClick,
  items=[],
  isOpen=false,
  onMenuItemChildClick=null,
}) => {
  const theme = useTheme();
  const { menuItemIconColorActive, menuItemColorActive, headingAndIconColor } =
    theme.sidebar;

  const ExpandIcon = () => ((
    <>
      {isCollapsible &&
        <>
          {isOpen && <KeyboardArrowUpRounded />}
          {!isOpen && <KeyboardArrowDownRounded />}
        </>
      }
    </>
  ));

  return (
    <>
      <ListItemButton
        onClick={onClick}
        sx={{
          ':before': {
            content: '""',
            position: 'relative',
            left: theme.sidebar.bookmarkOffset || '-15px',
            visibility: isSelected ? 'visible' : 'hidden',
            borderRadius: 0.5,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            width: 5,
            height: 35,
            backgroundColor: menuItemIconColorActive,
          },
        }}
      >
        <ListItemIcon>
          {Icon && (
            <Icon
              sx={{
                color: isSelected ?
                  menuItemIconColorActive :
                  headingAndIconColor,
              }}
              fontSize="small"
            />
          )}
        </ListItemIcon>
        <ListItemText
          sx={{
            color: isSelected ? menuItemColorActive : 'inherit',
          }}
        >
          {title}
        </ListItemText>
        <ExpandIcon />
      </ListItemButton>

      {isCollapsible && (
        <Collapse timeout={200} in={isOpen}>
          <List sx={{ paddingBottom: 2, paddingLeft: 0.5 }} disablePadding>
            {useMemo(
                () =>
                  items?.map(({ link, name }) => (
                    <ListItemButton
                      key={`${title}-${name}`}
                      onClick={() => onMenuItemChildClick?.(link)}
                    >
                      <ListItemIcon>
                        <Box
                          visibility={
                          selectedChildItem === name ? 'visible' : 'hidden'
                          }
                          sx={{
                            borderRadius: '50%',
                            width: 7,
                            height: 7,
                            marginRight: 1.5,
                            backgroundColor: menuItemIconColorActive,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color:
                          selectedChildItem === name ?
                            menuItemColorActive :
                            'inherit',
                        }}
                      >
                        {name}
                      </ListItemText>
                    </ListItemButton>
                  )),
                [items, selectedChildItem],
            )}
          </List>
        </Collapse>
      )}
    </>
  );
};

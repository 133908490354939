import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';

const queryOptions = {
  onError: null,
  onFinish: null,
};

const initialState = {
  ferry: null,
  ferries: [],
  ferriesByHarbor: [],
};

const slice = createSlice({
  name: 'ferry',
  initialState,
  reducers: {
    setFerry(state, action) {
      state.ferry = action.payload;
    },
    updatePosition(state, action) {
      const updatedFerryIndex = state.ferries.findIndex(({ id }) => id === action.payload.ferryId);

      const updatedLocation = {
        locationLatitude: action.payload.latitude,
        locationLongitude: action.payload.longitude,
        locationUpdated: new Date().toUTCString(),
        locationTrueHeading: action.payload.heading,
        dockId: action.payload?.dockId,
      };

      if (updatedFerryIndex !== -1) {
        state.ferries[updatedFerryIndex] = {
          ...state.ferries?.[updatedFerryIndex],
          ...updatedLocation,
        };
      };

      if (state.ferry?.id !== action.payload?.ferryId) return;
      state.ferry = {
        ...state.ferry,
        ...updatedLocation,
      };
    },
    getFerriesByHarbor(state, action) {
      state.ferriesByHarbor = action.payload;
    },
    getFerriesByCompany(state, action) {
      state.ferries = action.payload;
    },
    createFerry(state, action) {
      state.ferries = [...state.ferries, action.payload];
    },
    updateFerryHarbor(state, action) {
      const index = state.ferries.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.ferries[index] = action.payload;
    },
    updateFerryEdge(state, action) {
      const index = state.ferries.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.ferries[index] = action.payload;
    },
    updateFerry(state, action) {
      const index = state.ferries.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.ferries[index] = action.payload;
    },
    deleteFerry(state, action) {
      state.ferries = state.ferries.filter(
          (ferry) => ferry.id !== action.payload.id,
      );
    },
    deleteHarborAssignment(state, action) {
      const index = state.ferries.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.ferries[index] = action.payload;
    },
    deleteEdgeAssignment(state, action) {
      const index = state.ferries.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.ferries[index] = action.payload;
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

async function uploadImageAndRefetch(ferryId, file, dispatch) {
  if (file === '' || ferryId === undefined) return;
  const formData = new FormData();

  formData.append('file', file);

  return await axios
      .post(
          `${API}/ferry/image/${ferryId}`,
          { file: formData },
          {
            headers: { 'Content-Type': 'multipart/formdata;' },
            transformRequest: ({ file }) => file,
          },
      )
      .then((res) => dispatch(slice.actions.updateFerry(res.data)))
      .catch(errorHandler);
}

export const updateFerryPosition = (eventObject) => async (dispatch) => {
  dispatch(slice.actions.updatePosition(eventObject));
};

export const getFerryById = (ferryId, options=queryOptions) => async (dispatch) => {
  if (!ferryId) return;

  const { onError, onFinish } = options;


  axios.get(`${API}/ferry/${ferryId}`)
      .catch((error) => onError?.(error))
      .then((res) => {
        if (!res?.data) return;

        dispatch(slice.actions.updateFerry(res?.data));
        dispatch(slice.actions.setFerry(res?.data));
        onFinish?.(res);
      });
};

export const { reducer } = slice;

const errorHandler = (error) => console.log(error);

export const setFerry = (ferry) => async (dispatch) => {
  try {
    dispatch(slice.actions.setFerry(ferry));
  } catch (error) {
    console.error(error);
  }
};

export const getFerriesByHarbor = (harborId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API}/ferry/harbor/${harborId}`);

    dispatch(slice.actions.getFerriesByHarbor(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const getFerriesByCompany =
  (companyId, initialFetch = false) =>
    async (dispatch) => {
      try {
        const res = await axios.get(`${API}/ferry/company/${companyId}`);
        dispatch(slice.actions.getFerriesByCompany(res.data));

        const ferries = JSON.parse(JSON.stringify(res.data));
        if (initialFetch) {
          const kommandoren = ferries?.filter(
              ({ name }) => name === 'MF Kommandøren',
          );

          dispatch(slice.actions.setFerry(kommandoren[0] || ferries[0]));
        }
      } catch (error) {
        console.error(error);
      }
    };

export const createFerry =
  (name, companyId, image, MMSI) => async (dispatch) => {
    const data = await axios
        .post(`${API}/ferry`, {
          name,
          companyId,
          MMSI,
        })
        .then((res) => dispatch(slice.actions.createFerry(res.data)))
        .catch(errorHandler);

    await uploadImageAndRefetch(data?.payload?.id, image, dispatch);
  };

export const updateFerry =
  (ferryId, name, companyId, MMSI, file) => async (dispatch) => {
    await uploadImageAndRefetch(ferryId, file, dispatch);

    await axios
        .patch(`${API}/ferry/${ferryId}`, {
          name,
          companyId,
          MMSI,
          id: ferryId,
        })
        .then((res) => dispatch(slice.actions.updateFerry(res.data)))
        .catch(errorHandler);
  };

export const updateFerryHarbor = (ferryId, harborId) => async (dispatch) => {
  try {
    const res = await axios.post(
        `${API}/ferry/${ferryId}/assign-to-harbor/${harborId}`,
        {},
    );

    dispatch(slice.actions.updateFerryHarbor(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const deleteHarborAssignment = (assignmentId) => async (dispatch) => {
  try {
    const res = await axios.delete(
        `${API}/ferry/harbor-assignment/${assignmentId}`,
    );

    dispatch(slice.actions.deleteHarborAssignment(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const deleteFerry = (ferryId) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API}/ferry/${ferryId}`);

    dispatch(slice.actions.deleteFerry(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

import { Box } from '@mui/material';
import React from 'react';
import CountingHeroLogo from './icons/CountingHeroLogo';

const Logo = ({
  height = '100px',
  width = '100%',
  mode = 'full',
  BoxSx = {},
  ...other
}) => {
  return (
    <Box sx={BoxSx}>
      {mode === 'icon' && (
        <CountingHeroLogo width={width} />
      )}
      {mode === 'full' && (
        <img
          src={'/logo_full.png'}
          height={height}
          width={width}
          {...other}
        />
      )}
      {mode === 'minimal' && (
        <img
          src={'/logo_minimal.png'}
          height={height}
          width={width}
          {...other}
        />
      )}
    </Box>
  );
};

export default Logo;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';
import { setCompany } from './company';
import { reset as resetCompany } from './company';
import { reset as resetEdge } from './edge';
import { reset as resetFerry } from './ferry';
import { reset as resetHarbor } from './harbor';
import { reset as resetLabel } from './label';
import { reset as resetSystem } from './system';
import { reset as resetVoyage } from './voyage';

const initialState = {
  token: null,
  user: null,
  role: null,
  isAuthenticated: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.role = action.payload.role;
      state.groups = action.payload.groups || [];
      state.isAuthenticated = true;
    },
    logout(state, action) {
      state.user = null;
      state.token = null;
      state.role = null;
      state.isAuthenticated = false;
    },
    updateUser(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

const errorHandler = (error) => console.error(error);

export const { reducer } = slice;

export const login = (loginData) => async (dispatch) => {
  try {
    localStorage.setItem('token', loginData.token);
    dispatch(slice.actions.login(loginData));
    dispatch(setCompany(loginData.company));
  } catch (error) {
    errorHandler(error);
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem('rootState');
    localStorage.removeItem('token');

    dispatch(resetEdge());
    dispatch(resetFerry());
    dispatch(resetHarbor());
    dispatch(resetLabel());
    dispatch(resetSystem());
    dispatch(resetVoyage());
    dispatch(resetCompany());
    dispatch(slice.actions.logout());
  } catch (error) {
    errorHandler(error);
  }
};

export const updateUser = (id, data, image) => async (dispatch) => {
  if (image) {
    await axios
        .post(`${API}/user/image/${id}`, image, {
          headers: { 'Content-Type': 'multipart/formdata;' },
          transformRequest: (image) => image,
        })
        .catch(errorHandler);
  }

  axios
      .patch(`${API}/user/${id}`, data)
      .then((res) => dispatch(slice.actions.updateUser(res.data)))
      .catch(errorHandler);
};

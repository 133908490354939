import { alpha, createTheme, darken, lighten } from '@mui/material';
import { blue, deepPurple, green, orange, red } from '@mui/material/colors';

const baseColors = {
  primary: deepPurple[600],
  secondary: blue[600],
  paper: '#0B0D0E', // grey[900],
  background: '#000000', // darken(grey[900], 0.3),
  success: green[600],
  warning: orange[600],
  error: red[400],
  info: '#33C2FF',
  black: '#223354',
  white: '#ffffff',
};

const colors = {
  paper: baseColors.paper,
  background: baseColors.background,
  sidebar: {
    headingAndIconColor: baseColors.white,
    background: baseColors.paper,
    menuItemColorActive: baseColors.primary,
    menuItemIconColor: lighten(baseColors.secondary, 0.3),
    menuItemIconColorActive: baseColors.primary,
  },
  alpha: {
    white: {
      5: alpha(baseColors.white, 0.02),
      10: alpha(baseColors.white, 0.1),
      30: alpha(baseColors.white, 0.3),
      50: alpha(baseColors.white, 0.5),
      70: alpha(baseColors.white, 0.7),
      100: baseColors.white,
    },
    trueWhite: {
      5: alpha(baseColors.white, 0.02),
      10: alpha(baseColors.white, 0.1),
      30: alpha(baseColors.white, 0.3),
      50: alpha(baseColors.white, 0.5),
      70: alpha(baseColors.white, 0.7),
      100: baseColors.white,
    },
    black: {
      5: alpha(baseColors.black, 0.02),
      10: alpha(baseColors.black, 0.1),
      30: alpha(baseColors.black, 0.3),
      50: alpha(baseColors.black, 0.5),
      70: alpha(baseColors.black, 0.7),
      100: baseColors.black,
    },
  },
  secondary: {
    light: lighten(baseColors.secondary, 0.25),
    main: baseColors.secondary,
    dark: darken(baseColors.secondary, 0.2),
    darker: darken(baseColors.secondary, 0.6),
    contrastText: lighten(baseColors.secondary, 0.5),
  },
  primary: {
    light: lighten(baseColors.primary, 0.3),
    main: baseColors.primary,
    dark: darken(baseColors.primary, 0.2),
    darker: darken(baseColors.primary, 0.6),
    contrastText: lighten(baseColors.secondary, 2),
  },
  success: {
    light: lighten(baseColors.success, 0.3),
    main: baseColors.success,
    dark: darken(baseColors.success, 0.2),
    darker: darken(baseColors.success, 0.6),
    contrastText: lighten(baseColors.success, 2),
  },
  warning: {
    light: lighten(baseColors.warning, 0.3),
    main: baseColors.warning,
    dark: darken(baseColors.warning, 0.2),
    darker: darken(baseColors.warning, 0.6),
    contrastText: lighten(baseColors.warning, 2),
  },
  error: {
    light: lighten(baseColors.error, 0.3),
    main: baseColors.error,
    dark: darken(baseColors.error, 0.2),
    darker: darken(baseColors.error, 0.6),
    contrastText: lighten(baseColors.error, 2),
  },
  info: {
    light: lighten(baseColors.info, 0.3),
    main: baseColors.info,
    dark: darken(baseColors.info, 0.2),
    darker: darken(baseColors.info, 0.6),
    contrastText: lighten(baseColors.info, 2),
  },
};


export const NordicMidnight = createTheme({
  sidebar: {
    headingAndIconColor: colors.sidebar.headingAndIconColor,
    background: colors.sidebar.background,
    menuItemColorActive: colors.sidebar.menuItemColorActive,
    menuItemIconColor: colors.sidebar.menuItemIconColor,
    menuItemIconColorActive: colors.sidebar.menuItemIconColorActive,
    width: '260px',
    bookmarkOffset: '-15px',
  },
  header: {
    height: '64px',
    textColor: colors.secondary.light,
  },
  shape: {
    borderRadius: 10,
  },
  spacing: 10,
  palette: {
    mode: 'dark',
    background: {
      default: colors.background,
      paper: colors.paper,
    },
    text: {
      primary: colors.alpha.trueWhite[100],
      secondary: colors.alpha.white[100],
    },
    primary: colors.primary,
    secondary: colors.secondary,
    success: colors.success,
    warning: colors.warning,
    error: colors.error,
    info: colors.info,
    tonalOffset: 0.5,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1840,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Prevents uppercase transformation of button text
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: colors.paper,
        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 6,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none',
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: 6,
            background: colors.alpha.white[100],
            color: colors.alpha.black[100],
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          'backdropFilter': 'blur(2px)',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: 4,
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        marginLeft: 8,
        marginRight: 8,
        fontWeight: 'bold',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      'html, body': {
        width: '100%',
        height: '100%',
      },
      'body': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        flex: 1,
      },
      '#__next': {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      },
      'html': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
      },
      '.child-popover .MuiPaper-root .MuiList-root': {
        flexDirection: 'column',
      },
      '#nprogress': {
        pointerEvents: 'none',
      },
      '#nprogress .bar': {
        background: colors.primary.lighter,
      },
      '#nprogress .spinner-icon': {
        borderTopColor: colors.primary.lighter,
        borderLeftColor: colors.primary.lighter,
      },
      '#nprogress .peg': {
        boxShadow:
          '0 0 15px ' +
          colors.primary.lighter +
          ', 0 0 8px' +
          colors.primary.light,
      },
      ':root': {
        '--swiper-theme-color': colors.primary.main,
      },
      'code': {
        background: colors.info.lighter,
        color: colors.info.dark,
        borderRadius: 4,
        padding: 4,
      },
      '@keyframes pulse': {
        '0%': {
          transform: 'scale(.75)',
        },
        '20%': {
          transform: 'scale(1.1)',
        },
        '40%': {
          transform: 'scale(.75)',
        },
        '60%': {
          transform: 'scale(1.05)',
        },
        '80%': {
          transform: 'scale(.75)',
        },
        '100%': {
          transform: 'scale(.75)',
        },
      },
      '@keyframes ripple': {
        '0%': {
          transform: 'scale(.8)',
          opacity: 1,
        },
        '100%': {
          transform: 'scale(2.8)',
          opacity: 0,
        },
      },
      '@keyframes float': {
        '0%': {
          transform: 'translate(0%, 0%)',
        },
        '100%': {
          transform: 'translate(3%, 3%)',
        },
      },
    },
  },
  typography: {
    h1: {
      fontSize: 35,
      fontWeight: 600,
    },
    h2: {
      fontSize: 30,
      fontWeight: 600,
    },
    h3: {
      fontSize: 25,
      fontWeight: 600,
    },
    h4: {
      fontSize: 20,
      fontWeight: 500,
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
    },
    h6: {
      fontSize: 16,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    caption: {
      fontSize: 13,
    },
    subtitle1: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 15,
    },
    overline: {
      fontSize: 13,
    },
  },
});

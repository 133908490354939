import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function checkMobile(theme) {
  const dimensions = getWindowDimensions();
  const isMobile = dimensions.width <= theme.breakpoints.values.lg;
  return isMobile;
}


export default function useWindowDimensions() {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(checkMobile(theme));
  const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions(),
  );

  const throttle = (callbackFn, limit = 500) => {
    let wait = false;
    return function() {
      if (!wait) {
        callbackFn();
        wait = true;
        setTimeout(function() {
          wait = false;
        }, limit);
      }
    };
  };

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
    setIsMobile(checkMobile(theme));
  };

  useEffect(() => {
    window.addEventListener('resize', throttle(handleResize));
    return () => window.removeEventListener('resize', throttle(handleResize) );
  }, []);

  return { width: windowDimensions.width, height: windowDimensions.height, isMobile };
}

/* eslint-disable require-jsdoc */
import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import AppInit from './components/AppInit';
import AuthGuard from './components/guards/AuthGuard';
import GuestGuard from './components/guards/GuestGuard';
import { UserProfile } from './pages/user/profile';
import { Sidebar } from './components/sidebar';

// eslint-disable-next-line react/display-name
function loadable(Component) {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <Suspense fallback={<AppInit />}>
      <Component {...props} />
    </Suspense>
  );
}

// Page imports
const Login = loadable(lazy(() => import('./pages/auth/Login')));
const AdminRegistration = loadable(
    lazy(() => import('./pages/auth/AdminRegister')),
);

const Corrections = loadable(
    lazy(() => import('./pages/administration/resources/Correction')),
);

const RegisterByInvite = loadable(
    lazy(() => import('./pages/auth/RegisterByInvite')),
);
const ResetPassword = loadable(
    lazy(() => import('./pages/auth/ResetPassword')),
);

const Boardings = loadable(
    lazy(() => import('./pages/counting/Boardings')),
);
const Cars = loadable(lazy(() => import('./pages/counting/Cars')));
const PerformanceDashboard = loadable(
    lazy(() => import('./pages/administration/resources/PerformanceDashboard')),
);
const Voyages = loadable(lazy(() => import('./pages/counting/Voyages')));
const Statistics = loadable(
    lazy(() => import('./pages/administration/resources/Statistics')),
);
const VoyageBoardings = loadable(
    lazy(() => import('./pages/counting/voyage/VoyageDetails')),
);

const Ferries = loadable(
    lazy(() => import('./pages/administration/resources/Ferries')),
);
const Docks = loadable(
    lazy(() => import('./pages/administration/resources/Docks')),
);
const Ferry = loadable(
    lazy(() => import('./pages/FerryDashboard')),
);
const Harbors = loadable(
    lazy(() => import('./pages/administration/resources/Harbors')),
);
const Edges = loadable(
    lazy(() => import('./pages/administration/resources/Edges')),
);
const Components = loadable(
    lazy(() => import('./pages/administration/resources/Components')),
);
const Users = loadable(lazy(() => import('./pages/administration/Users')));
const Schedule = loadable(
    lazy(() => import('./pages/administration/resources/Schedule')),
);

const Settings = loadable(lazy(() => import('./pages/system/Settings')));
const Companies = loadable(lazy(() => import('./pages/system/Companies')));
const Company = loadable(
    lazy(() => import('./pages/system/companyManagement/Company')),
);
const SudoEdges = loadable(
    lazy(() => import('./pages/system/companyManagement/SudoEdges')),
);
const SudoFerries = loadable(
    lazy(() => import('./pages/system/companyManagement/SudoFerries')),
);
const SudoHarbors = loadable(
    lazy(() => import('./pages/system/companyManagement/SudoHarbors')),
);

// General pages
const Changelog = loadable(lazy(() => import('./pages/general/Changelog')));

const routes = [
  {
    path: '/changelog',
    element: <Changelog />,
  },
  {
    path: '/auth',
    element: <GuestGuard />,
    children: [
      {
        path: 'login',
        children: [
          { index: true, element: <Login /> },
          {
            path: 'admin',
            element: <AdminRegistration />,
          },
        ],
      },
      {
        path: 'invited',
        element: <RegisterByInvite />,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <Sidebar />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/dashboard/company/ferry" />,
      },
      {
        path: 'profile/:user',
        element: <UserProfile />,
      },
      {
        path: 'counting',
        children: [
          {
            path: 'boardings',
            element: <Boardings />,
          },
          {
            path: 'performance',
            element: <PerformanceDashboard />,
          },
          {
            path: 'cars',
            children: [
              { index: true, element: <Cars /> },
              {
                path: 'statistics',
                element: <Statistics />,
              },
            ],
          },
          {
            path: 'voyages',
            element: <Voyages />,
          },
          {
            path: 'voyage',
            children: [
              {
                path: 'boardings',
                element: <VoyageBoardings />,
              },
            ],
          },
        ],
      },
      {
        path: 'company',
        children: [
          {
            path: 'harbors',
            children: [
              { index: true, element: <Harbors /> },
              {
                path: 'schedule',
                element: <Schedule />,
              },
            ],
          },
          {
            path: 'ferries',
            element: <Ferries />,
          },
          {
            path: 'corrections',
            element: <Corrections />,
          },
          {
            path: 'ferry',
            element: <Ferry />,
          },
          {
            path: 'docks',
            element: <Docks />,
          },
          {
            path: 'edges',
            children: [
              { index: true, element: <Edges /> },
              {
                path: ':id/components',
                element: <Components />,
              },
            ],
          },
          {
            path: 'users',
            element: <Users />,
          },
        ],
      },
      {
        path: 'system',
        children: [
          {
            path: 'settings',
            element: <Settings />,
          },
          {
            path: 'companies',
            children: [
              { index: true, element: <Companies /> },
              {
                path: 'company',
                element: <Company />,
              },
              {
                path: 'harbors',
                element: <SudoHarbors />,
              },
              {
                path: 'ferries',
                element: <SudoFerries />,
              },
              {
                path: 'edges',
                element: <SudoEdges />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <Navigate to="/auth/login" />,
  },
];

export default routes;

import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import routes from './routes';

function App() {
  const elements = useRoutes(routes);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    if (token) {
      axios.defaults.headers['Authorization'] = `Bearer ${token}`;
      // When the user is in the dashboard we know that there
      // is a token present, so we configure axios to use it.
      axios.interceptors.request.use(
          // Modify the config to include our default values.
          function(config) {
            config.headers['Authorization'] = `Bearer ${token}`;

            return config;
          },
          // Catch the error, we need this so the error
          // doesn't get intercepted and hidden.
          null,
          {},
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <>{elements}</>;
}

export default Sentry.withProfiler(App);

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';
import { format } from 'date-fns';
import days from '../../utils/days';

const initialState = {
  selectedHarbor: null, // Selected harbor (when in car/boarding/ferry view)
  harbor: null, // Selected harbor (when in harbor view)
  harbors: [], // List of all harbors
};

const slice = createSlice({
  name: 'harbor',
  initialState,
  reducers: {
    createHarbor(state, action) {
      state.harbor = action.payload;
      state.harbors = [...state.harbors, action.payload];
    },
    getHarborsByCompany(state, action) {
      state.harbors = action.payload;
    },
    deleteHarbor(state, action) {
      state.harbors = state.harbors.filter(
          (item) => item.id !== action.payload,
      );
    },
    setHarbor(state, action) {
      state.harbor = action.payload;
    },
    createSchedule(state, action) {
      state.harbor.schedule = action.payload;
      const index = state.harbors.findIndex(
          (item) => item.id === action.payload.harbor.id,
      );
      state.harbors[index].schedule = action.payload;
    },
    createPlannedVoyage(state, action) {
      const updatedHarbor = state.harbor;
      updatedHarbor.schedule[action.payload.day].push(action.payload);
      updatedHarbor.schedule[action.payload.day].sort((a, b) => {
        const aTime = parseInt(
            `${format(new Date(a.timestamp), 'HH')}${format(
                new Date(a.timestamp),
                'mm',
            )}`,
        );
        const bTime = parseInt(
            `${format(new Date(b.timestamp), 'HH')}${format(
                new Date(b.timestamp),
                'mm',
            )}`,
        );

        if (aTime > bTime) return 1;
        if (aTime < bTime) return -1;

        return 0;
      });

      state.harbor = updatedHarbor;
      const index = state.harbors.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.harbors[index] = updatedHarbor;
    },
    deletePlannedVoyage(state, action) {
      const updatedHarbor = JSON.parse(JSON.stringify(state.harbor));
      updatedHarbor.schedule[action.payload.day] = updatedHarbor.schedule[
          action.payload.day
      ].filter((item) => item.id !== action.payload.id);

      state.harbor = updatedHarbor;
      const index = state.harbors.findIndex(
          (item) => item.id === action.payload.harborId,
      );
      state.harbors[index] = updatedHarbor;
    },
    assignFerryToVoyage(state, action) {
      const index = state.harbor.schedule[action.payload.day].findIndex(
          (item) => item.id === action.payload.id,
      );
      state.harbor.schedule[action.payload.day][index] = action.payload;
    },
    setSelectedHarbor(state, action) {
      state.selectedHarbor = action.payload;
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

export const { reducer } = slice;

export const createHarbor = (companyId, name) => async (dispatch) => {
  try {
    const res = await axios.post(`${API}/harbor`, {
      companyId,
      name,
    });

    dispatch(slice.actions.createHarbor(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const getHarborsByCompany =
  (companyId, initialFetch = false, onError=null) =>
    async (dispatch) => {
      try {
        const res = await axios.get(`${API}/harbor/company/${companyId}`).catch((error) => onError?.(error));

        const harbors = JSON.parse(JSON.stringify(res.data));

        if (initialFetch) {
          dispatch(slice.actions.setSelectedHarbor(harbors[0]));
        }

        dispatch(slice.actions.getHarborsByCompany(harbors));
      } catch (error) {
        console.error(error);
      }
    };

export const deleteHarbor = (harborId) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API}/harbor/${harborId}`);

    dispatch(slice.actions.deleteHarbor(res.data.id));
  } catch (error) {
    console.error(error);
  }
};

export const setHarbor = (harbor) => async (dispatch) => {
  try {
    dispatch(slice.actions.setHarbor(harbor));
  } catch (error) {
    console.error(error);
  }
};

export const createSchedule = (harborId) => async (dispatch) => {
  try {
    const res = await axios.post(`${API}/schedule`, {
      harborId,
    });

    for (const day of days) {
      res.data[day.value] = [];
    }

    dispatch(slice.actions.createSchedule(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const createPlannedVoyage =
  (timestamp, day, scheduleId, harborId) => async (dispatch) => {
    try {
      const res = await axios.post(
          `${API}/schedule/${scheduleId}/${harborId}`,
          {
            timestamp: timestamp.toISOString(),
            time: timestamp.toISOString().slice(11, 16),
            day,
          },
      );

      dispatch(slice.actions.createPlannedVoyage(res.data));
    } catch (error) {
      console.error(error);
    }
  };

export const assignFerryToVoyage =
  (plannedVoyageId, ferryId) => async (dispatch) => {
    try {
      const res = await axios.patch(
          `${API}/schedule/planned-voyage/${plannedVoyageId}`,
          {
            ferryId,
          },
      );

      dispatch(slice.actions.assignFerryToVoyage(res.data));
    } catch (error) {
      console.error(error);
    }
  };

export const setSelectedHarbor = (harbor) => async (dispatch) => {
  try {
    dispatch(slice.actions.setSelectedHarbor(harbor));
  } catch (error) {
    console.error(error);
  }
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

export const deletePlannedVoyage = (plannedVoyageId) => async (dispatch) => {
  try {
    const res = await axios.delete(
        `${API}/schedule/planned-voyage/${plannedVoyageId}`,
    );

    dispatch(slice.actions.deletePlannedVoyage(res.data));
  } catch (error) {
    console.error(error);
  }
};

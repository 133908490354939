import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';

const initialState = {
  logs: [],
};

const slice = createSlice({
  name: 'changelog',
  initialState,
  reducers: {
    getChangelogs(state, action) {
      state.logs = action.payload;
    },
    createChangelog(state, action) {
      state.logs = [action.payload, ...state.logs];
    },
    updateChangelog(state, action) {
      const index = state.logs.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.logs[index] = action.payload;
    },
    deleteChangelog(state, action) {
      state.logs = state.logs.filter(
          (logItem) => logItem.id !== action.payload.id,
      );
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

export const { reducer } = slice;

const errorHandler = (error) => console.error(error);

export const getChangelogs = () => (dispatch) => {
  axios
      .get(`${API}/changelog`)
      .then((res) => dispatch(slice.actions.getChangelogs(res.data)))
      .catch(errorHandler);
};

export const createChangeLog = (changeLog) => (dispatch) => {
  axios
      .post(`${API}/changelog`, { ...changeLog })
      .then((res) => dispatch(slice.actions.createChangelog(res.data)))
      .catch(errorHandler);
};

export const updateChangeLog = (id, title, description) => (dispatch) => {
  axios
      .patch(`${API}/changelog/${id}`, { id, title, description })
      .then((res) => dispatch(slice.actions.updateChangelog(res.data)))
      .catch(errorHandler);
};

export const deleteChangeLog = (id) => (dispatch) => {
  axios
      .delete(`${API}/changelog/${id}`, { id })
      .then((res) => dispatch(slice.actions.deleteChangelog(res.data)))
      .catch(errorHandler);
};

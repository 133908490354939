import { ThemeProvider } from '@mui/material';
import { createContext, useEffect, useState } from 'react';
import { THEMES, getThemeConfig } from './schemes';

export const ThemeContext = createContext((_themeName) => {});

const ThemeProviderWrapper = (props) => {
  const [theme, setTheme] = useState(THEMES.DEFAULT_THEME);

  useEffect(() => {
    const currentTheme = window.localStorage.getItem('appTheme');
    if (currentTheme?.length > 0) setTheme(currentTheme);
  }, []);

  const handleSetTheme = (themeName = THEMES.DEFAULT_THEME) => {
    window.localStorage.setItem('appTheme', themeName);
    setTheme(themeName);
  };

  return (
    <ThemeContext.Provider value={handleSetTheme}>
      <ThemeProvider theme={getThemeConfig(theme)}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API } from '../../Config';
import PageWrapper from '../../components/PageWrapper';
import { updateUser } from '../../store/slices/user';
import { ThemeContext } from '../../theme/ThemeProvider';
import { THEMES } from '../../theme/schemes';

export function UserProfile() {
  const { user } = useSelector((state) => state.user);
  const changeTheme = useContext(ThemeContext);

  const [selectedTheme, setSelectedTheme] = useState(window.localStorage.getItem('appTheme') || THEMES.DEFAULT_THEME);
  const [file, setFile] = useState(undefined);
  const [preview, setPreview] = useState(undefined);
  const [username, setUsername] = useState(user.name);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();

  const onRequestResetPassword = () => {
    axios
        .post(`${API}/auth/reset-password`, {
          email,
        })
        .then(() => {
          enqueueSnackbar('Email with reset link sucscessfully sent!', {
            variant: 'success',
          });
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('An error occurred attempting to generate reset link', {
            variant: 'error',
          });
        });
  };

  const onSaveChanges = () => {
    let image = undefined;

    if (file) {
      image = new FormData();
      image.append('file', file);
    }
    dispatch(updateUser(user.id, { name: username }, image));
  };

  const onCancelFileUpload = () => {
    setFile(undefined);
    setPreview(undefined);
  };

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return (
    <PageWrapper title="User Profile">
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          flexWrap: 'wrap',
          mt: 2,
          maxWidth: '1000px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 3,
            width: '100%',
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column',
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="100%"
            m={3}
          >
            <Avatar
              sx={{
                width: 200,
                height: 200,
                [theme.breakpoints.down('sm')]: {
                  width: 150,
                  height: 150,
                },
              }}
              src={preview || user.avatar}
              alt={user.name}
            />
            <Button
              variant="contained"
              component="label"
              size="small"
              sx={{ mb: 2, mt: 2, width: '200px' }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={({ target }) => setFile(target.files[0])}
              />
              Choose
            </Button>
            <Button
              variant="outlined"
              sx={{ width: '200px' }}
              component="label"
              color="error"
              size="small"
              disabled={!preview?.length > 0}
              onClick={onCancelFileUpload}
            >
              Cancel
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            width="100%"
            m={2}
            mt={3}
          >
            <Typography variant="subtitle1" mb={2}>
              Basic Information
            </Typography>
            <TextField
              sx={{ marginBottom: '20px', width: '80%' }}
              variant="standard"
              label="Username"
              defaultValue={user.name}
              value={username}
              onChange={({ currentTarget }) => setUsername(currentTarget.value)}
              size="medium"
            />
            <TextField
              sx={{ marginBottom: '20px', width: '80%' }}
              variant="standard"
              label="Email Address"
              type="email"
              disabled
              defaultValue={user.email}
              size="medium"
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="left"
              width="100%"
              mt={5}
            >
              <Typography variant="subtitle1" mb={2}>
                Actions
              </Typography>
              <Button
                variant="text"
                size="small"
                sx={{ width: 'fit-content' }}
                onClick={onRequestResetPassword}
              >
                Reset Password
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              width="100%"
              mt={5}
            >
              <Typography variant="subtitle1" mb={2}>
                Other settings
              </Typography>
              <FormControl>
                <InputLabel>Website theme</InputLabel>
                <Select
                  label="website theme"
                  variant='standard'
                  autoWidth
                  value={selectedTheme}
                  onChange={(event) => {
                    setSelectedTheme(event.target.value);
                    changeTheme(event.target.value);
                  }}
                >
                  {Object.values(THEMES).map((themeName) => (
                    <MenuItem key={themeName} value={themeName}>
                      {themeName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            width: '100%',
            p: 2,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={onSaveChanges}
            color="success"
            variant="contained"
            disabled={username.length === 0}
          >
            Save Changes
          </Button>
        </CardActions>
      </Card>
    </PageWrapper>
  );
}

import {
  AnchorOutlined,
  BusinessCenterOutlined,
  DirectionsBoatOutlined,
  DirectionsCarOutlined,
  PeopleOutlineOutlined,
  PublishedWithChangesOutlined,
  SailingOutlined,
  SettingsOutlined,
  StorageOutlined,
} from '@mui/icons-material';
import { ROLES } from '../../constants';

const commonItems = [
  {
    name: 'Ferry Dashboard',
    Icon: SailingOutlined,
    link: '/dashboard/company/ferry',
  },
  {
    heading: 'Counting',
    name: 'Voyages',
    link: '/dashboard/counting/voyages',
    Icon: AnchorOutlined,
  },
  {
    name: 'Boardings',
    link: '/dashboard/counting/boardings',
    Icon: DirectionsBoatOutlined,
  },
  {
    name: 'Cars',
    link: '/dashboard/counting/cars',
    Icon: DirectionsCarOutlined,
  },
];

const adminItems = [
  {
    heading: 'Administration',
    name: 'Resources',
    Icon: StorageOutlined,
    items: [
      {
        name: 'Ferries',
        link: '/dashboard/company/ferries',
      },
      {
        name: 'Harbors',
        link: '/dashboard/company/harbors',
      },
      {
        name: 'Edges',
        link: '/dashboard/company/edges',
      },
      {
        name: 'Docks',
        link: '/dashboard/company/docks',
      },
      {
        name: 'Statistics',
        link: '/dashboard/counting/cars/statistics',
      },
      {
        name: 'Performance',
        link: '/dashboard/counting/performance',
      },
      {
        name: 'Corrections',
        link: '/dashboard/company/corrections',
      },
    ],
  },
  {
    name: 'Users',
    Icon: PeopleOutlineOutlined,
    link: '/dashboard/company/users',
  },
];

const sysAdminItems = [
  {
    heading: 'System',
    name: 'Settings',
    Icon: SettingsOutlined,
    link: '/dashboard/system/settings',
  },
  {
    name: 'Companies',
    Icon: BusinessCenterOutlined,
    link: '/dashboard/system/companies',
  },
];

const infoItems = [
  {
    heading: 'Info',
    name: 'Changelog',
    Icon: PublishedWithChangesOutlined,
    link: '/changelog',
  },
];

export const menuItems = {
  [ROLES.inspector]: [...commonItems, ...infoItems],
  [ROLES.controller]: [...commonItems, ...infoItems],
  [ROLES.admin]: [...commonItems, ...adminItems, ...infoItems],
  [ROLES.owner]: [...commonItems, ...adminItems, ...infoItems],
  [ROLES.sysAdmin]: [
    ...commonItems,
    ...adminItems,
    ...sysAdminItems,
    ...infoItems,
  ],
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';

const initialState = {
  labels: [],
};

const slice = createSlice({
  name: 'label',
  initialState,
  reducers: {
    getLabels(state, action) {
      state.labels = action.payload;
    },
    createLabel(state, action) {
      state.labels = [...state.labels, action.payload];
    },
    deleteLabel(state, action) {
      state.labels = state.labels.filter(
          (item) => item.id !== action.payload.id,
      );
    },
    reset(state, action) {
      state = initialState;
    },
    updateLabel(state, action) {
      const index = state.labels.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.labels[index] = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getLabels = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API}/label`);

    dispatch(slice.actions.getLabels(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const createLabel = (value, name, description) => async (dispatch) => {
  try {
    const res = await axios.post(`${API}/label`, {
      value,
      name,
      description,
    });

    dispatch(slice.actions.createLabel(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const updateLabel =
  (id, value, name, description) => async (dispatch) => {
    try {
      const res = await axios.patch(`${API}/label/${id}`, {
        value,
        name,
        description,
      });
      dispatch(slice.actions.updateLabel(res.data));
    } catch (error) {
      console.error(error);
    }
  };

export const deleteLabel = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API}/label/${id}`);

    dispatch(slice.actions.deleteLabel(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

import { AccountCircleOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, LogoutOutlined } from '@mui/icons-material';
import { Avatar, Box, Card, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../store/slices/user';

export function UserCard({ openMenu, onListItemClick, onProfileCardClick }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  return (
    <Card sx={{ margin: 1, padding: 1, minHeight: '60px' }}>
      <Stack direction="column">
        <Box
          onClick={() => onProfileCardClick(user?.email)}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
            width: '100%',
            flex: 1,
          }}
        >
          <Tooltip arrow title={`logged in as ${user?.email}`}>
            <Avatar>{user?.email?.at(0)?.toUpperCase()}</Avatar>
          </Tooltip>
          <Typography
            maxWidth="140px"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {user?.email}
          </Typography>
          <IconButton>
            {openMenu === user?.email ? (
              <KeyboardArrowUpOutlined />
            ) : (
              <KeyboardArrowDownOutlined />
            )}
          </IconButton>
        </Box>

        <Collapse in={openMenu === user?.email}>
          <List sx={{ paddingBottom: 0 }}>
            <ListItemButton key="profile" onClick={onListItemClick}>
              <ListItemIcon>
                <AccountCircleOutlined color="primary" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </ListItemButton>
            <ListItemButton key="logout" onClick={() => dispatch(logout())}>
              <ListItemIcon>
                <LogoutOutlined color="error" />
              </ListItemIcon>
              <ListItemText>logout</ListItemText>
            </ListItemButton>
          </List>
        </Collapse>
      </Stack>
    </Card>
  );
}

export const ROLES = {
  'invited': 'invited',
  'inspector': 'inspector',
  'controller': 'controller',
  'admin': 'admin',
  'owner': 'owner',
  'sysAdmin': 'sysAdmin',
};

export const ROLES_LEVEL = {
  'invited': 0,
  'inspector': 2,
  'controller': 4,
  'admin': 6,
  'owner': 8,
  'sysAdmin': 10,
};

export const FERRY_STATUS = {
  DOCKED: 'docked',
  SAILING: 'sailing',
  ARRIVING: 'arriving',
  LEAVING: 'leaving',
};

export const SETTING_TYPES = {
  EDGE_INACTIVITY_THRESHOLD: 'edge-inactivity-threshold',
  INSPECTION_CHANCE: 'ferry-boarding-random-inspection-chance',
  lOW_CONFIDENCE_THRESHOLD: 'lowConfidenceThreshold',
  CAR_TIME_LIMIT: 'carTimelimit',
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';

const initialState = {
  dock: null,
  docks: [],
};

const slice = createSlice({
  name: 'dock',
  initialState,
  reducers: {
    setDock(state, action) {
      state.dock = action.payload;
    },
    getDocksByCompany(state, action) {
      state.docks = action.payload;
    },
    createDock(state, action) {
      state.docks = [...state.docks, action.payload];
    },
    updateDock(state, action) {
      const index = state.docks.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.docks[index] = action.payload;
    },
    deleteDock(state, action) {
      state.docks = state.docks.filter((dock) => dock.id !== action.payload.id);
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

export const { reducer } = slice;

export const setDock = (dock) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDock(dock));
  } catch (error) {
    console.error(error);
  }
};

export const getDocksByCompany = (companyId) => async (dispatch) => {
  try {
    if (!companyId) return;

    const res = await axios.get(`${API}/dock/company/${companyId}`);

    dispatch(slice.actions.getDocksByCompany(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const createDock =
  (name, description, harborId, polygonPoints) => async (dispatch) => {
    try {
      const res = await axios.post(`${API}/dock`, {
        name,
        description,
        harborId,
        polygonPoints,
      });

      dispatch(slice.actions.createDock(res.data));
    } catch (error) {
      console.error(error);
    }
  };

export const updateDock =
  (dockId, name, description, harborId, polygonPoints) => async (dispatch) => {
    try {
      const res = await axios.patch(`${API}/dock/${dockId}`, {
        name,
        description,
        harborId,
        polygonPoints,
      });

      dispatch(slice.actions.updateDock(res.data));
    } catch (error) {
      console.error(error);
    }
  };

export const deleteDock = (dockId) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API}/dock/${dockId}`);

    dispatch(slice.actions.deleteDock(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

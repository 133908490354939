import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';

const initialState = {
  edge: null,
  edges: [],
};

const slice = createSlice({
  name: 'edge',
  initialState,
  reducers: {
    createEdge(state, action) {
      state.edge = action.payload;
      state.edges = [...state.edges, action.payload];
    },
    getEdgesByCompany(state, action) {
      state.edges = action.payload;
    },
    deleteEdge(state, action) {
      state.edges = state.edges
          ?.filter((item) => item.id !== action.payload)
          ?.sort((a, b) => a?.id > b?.id);
    },
    createComponent(state, action) {
      state.edge = action.payload;
      const index = state.edges.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.edges[index] = action.payload;
    },
    updateComponent(state, action) {
      const componentIndex = state.edge.components.findIndex(
          (item) => item.id === action.payload.id,
      );
      state.edge.components[componentIndex] = action.payload;
      const index = state.edges.findIndex(
          (item) => item.id === action.payload.edge.id,
      );
      state.edges[index].components[componentIndex] = action.payload;
    },
    getEdgeById(state, action) {
      state.edge = action.payload;
    },
    setEdge(state, action) {
      state.edge = action.payload;
    },
    updateEdge(state, action) {
      const index = state.edges.findIndex(({ id }) => id === action.payload.id);

      state.edges[index] = {
        ...state.edges[0],
        ...action.payload,
      };
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

export const { reducer } = slice;

export const createEdge = (companyId, name, isEdgeOnboardFerry) => async (dispatch) => {
  try {
    const res = await axios.post(`${API}/edge/company/${companyId}`, {
      name,
      isEdgeOnboardFerry: isEdgeOnboardFerry,
    });

    dispatch(slice.actions.createEdge(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const setEdge = (edge) => async (dispatch) => {
  try {
    dispatch(slice.actions.setEdge(edge));
  } catch (error) {
    console.error(error);
  }
};

export const getEdgesByCompany = (companyId, initialFetch) => async (dispatch) => {
  try {
    const res = await axios.get(`${API}/edge/company/${companyId}`);

    dispatch(slice.actions.getEdgesByCompany(res.data));

    const edges = JSON.parse(JSON.stringify(res.data));
    if (initialFetch) {
      const nesvik = edges?.filter(({ name }) => name === 'Nesvik');

      dispatch(slice.actions.setEdge(nesvik[0] || edges[0]));
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateEdgeFerryAssignments = (assignedFerries, assignmentsToDelete, edgeId) => async (dispatch) => {
  // duplicates are handeled in backend
  await axios.patch(`${API}/edge/${edgeId}/assign-ferries`, {
    data: {
      assignedFerries,
      assignmentsToDelete,
    },
  }).then((res) => dispatch(slice.actions.updateEdge(res.data)));
};

export const deleteEdge = (edgeId) => async (dispatch) => {
  try {
    const res = await axios.delete(`${API}/edge/${edgeId}`);

    dispatch(slice.actions.deleteEdge(res.data.id));
  } catch (error) {
    console.error(error);
  }
};

export const getEdgeById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`${API}/edge/${id}`);

    dispatch(slice.actions.getEdgeById(res.data[0]));
  } catch (error) {
    console.error(error);
  }
};

export const updateEdgeHarbor = (edgeId, harborId) => async (dispatch) => {
  try {
    const res = await axios.patch(`${API}/edge/${edgeId}/harbor`, {
      harborId,
    });

    dispatch(slice.actions.updateEdge(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const createComponent =
  (edgeId, name, value, note) => async (dispatch) => {
    try {
      const res = await axios.post(`${API}/edge/${edgeId}/component`, {
        name,
        value,
        note,
      });

      dispatch(slice.actions.createComponent(res.data));
    } catch (error) {
      console.error(error);
    }
  };

export const updateComponent =
  (id, value, timestamp, comment) => async (dispatch) => {
    try {
      const res = await axios.patch(`${API}/edge/component/${id}`, {
        value,
        timestamp,
        comment,
      });

      dispatch(slice.actions.updateComponent(res.data));
    } catch (error) {
      console.error(error);
    }
  };

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

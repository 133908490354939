import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';

const initialState = {
  company: null,
  companies: [],
  edges: [],
  harbors: [],
  ferries: [],
  voyages: [],
  tempUser: null,
  isSidebarOpen: true,
};

const slice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    getCompanies(state, action) {
      state.companies = action.payload;
    },
    setIsSidebarOpen(state, action) {
      state.isSidebarOpen = action?.payload || !state.isSidebarOpen;
    },
    createCompany(state, action) {
      state.companies = [...state.companies, action.payload];
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    getEdges(state, action) {
      state.edges = action.payload;
    },
    createEdge(state, action) {
      state.edges = [...state.edges, action.payload];
    },
    getHarbors(state, action) {
      state.harbors = action.payload;
    },
    createHarbor(state, action) {
      state.harbors = [...state.harbors, action.payload];
    },
    getVoyages(state, action) {
      state.voyages = action.payload;
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

export const { reducer } = slice;

export const getAllCompanies = () => async (dispatch) => {
  try {
    const res = await axios.get(`${API}/company`);

    dispatch(slice.actions.getCompanies(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const toggleSidebar = () => async (dispatch) => {
  dispatch(slice.actions.setIsSidebarOpen());
};

export const setSystemCompany = (company) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCompany(company));
  } catch (error) {
    console.error(error);
  }
};

export const createCompany = (data) => async (dispatch) => {
  try {
    const res = await axios.post(`${API}/company`, data);

    dispatch(slice.actions.createCompany(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../Config';
import api from '../../utils/api';
import { logout } from './user';

const initialState = {
  company: null,
  tempUser: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompany(state, action) {
      state.company = action.payload;
    },
    getCompanyByUser(state, action) {
      state.company = {
        ...state.company,
        ...action.payload,
      };
    },
    createUser(state, action) {
      state.company = {
        ...state.company,
        ...action.payload.company,
      };

      state.tempUser = action.payload.user;
    },
    deleteUser(state, action) {
      state.company = {
        ...state.company,
        ...action.payload,
      };
    },
    updateRole(state, action) {
      const { users } = state.company;

      const index = users.findIndex(
          (e) => e.userId === action.payload.userId,
      );
      // to keep assigned ferries in frontend after changing role.
      users[index] = {
        ...users[index],
        ...action?.payload,
        user: {
          ...users[index].user,
          ...action.payload.user,
          role: {
            ...users[index].user.role,
            role: action?.payload?.role, // role needs to update here too...
          },
        },
      };
    },
    updateUser(state, action) {
      const index = state.company.users?.findIndex(({ user }) => action.payload.user.id === user.id);

      const user = state.company.users[index];

      state.company.users[index] = { ...user, ...action.payload };
    },
    updateUserCorrections(state, action) {
      const userIndex = state.company.users.findIndex(
          ({ user }) => user.id === action.payload.userId,
      );

      const user = state.company.users[userIndex];

      const prevCars = user.user?.Car || [];

      const isNextCars = prevCars.length > 0 &&
        action.payload.cars?.[0].id !== prevCars[0].id &&
        prevCars[0].harborId === action.payload.cars?.[0].harborId;

      state.company.users[userIndex] = {
        ...user,
        user: {
          ...user.user,
          Car: isNextCars ? [
            ...prevCars,
            ...action.payload.cars,
          ] : action.payload.cars,
        },
      };
    },
    reset(state, action) {
      state = initialState;
    },
  },
});

function errorHandler(error) {
  console.error(error);
}

export const { reducer } = slice;

export const setCompany = (company) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCompany(company));
  } catch (error) {
    console.error(error);
  }
};

export const getUserCorrections = ({
  userId,
  skip,
  take,
}) => async (dispatch) => {
  axios.get(`${API}/Car/correctedBy/${userId}?take=${take}&skip=${skip}`)
      .then((res) => dispatch(slice.actions.updateUserCorrections({ userId, cars: res.data })));
};

export const getCompanyByUser = () => async (dispatch) => {
  try {
    if (!localStorage.getItem('token')) {
      dispatch(logout());
      return;
    }

    const res = await api.get('/company/user');

    dispatch(slice.actions.getCompanyByUser(res.data));
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const createUser = (companyId, email) => async (dispatch) => {
  try {
    const res = await axios.post(`${API}/company/${companyId}/user`, {
      email,
    });

    dispatch(slice.actions.createUser(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const createFerryAssignment = (ferryId, roleId, userId) => async (dispatch) => {
  try {
    await axios.post(`${API}/ferry/${ferryId}/assign-to-role/${roleId}`);

    const user = await axios.get(`${API}/user/${userId}`);

    dispatch(slice.actions.updateUser(user.data));
  } catch (error) {
    errorHandler(error);
  }
};

export const deleteFerryAssignment = (ferryId, roleId, userId) => async (dispatch) => {
  try {
    await axios.delete(`${API}/ferry/${ferryId}/assign-to-role/${roleId}`);

    const user = await axios.get(`${API}/user/${userId}`);

    dispatch(slice.actions.updateUser(user.data));
  } catch (error) {
    errorHandler(error);
  }
};

export const deleteUser = (companyId, userId) => async (dispatch) => {
  try {
    const res = await axios.delete(
        `${API}/company/${companyId}/user/${userId}`,
    );

    dispatch(slice.actions.deleteUser(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const updateRole = (companyId, userId, newRole) => async (dispatch) => {
  try {
    const res = await axios.patch(
        `${API}/company/${companyId}/user/${userId}`,
        {
          role: newRole,
        },
    );
    dispatch(slice.actions.updateRole(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const reset = () => async (dispatch) => {
  dispatch(slice.actions.reset());
};

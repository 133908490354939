import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { API } from '../../Config';
import { logout } from '../../store/slices/user';

const GuestGuard = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');

  const verifyToken = async () => {
    // validate token
    let isValid = false;

    await axios.get(`${API}/auth/verifyToken/?token=${token}`).then(({ data }) => {
      if (!data?.isValid) {
        isValid = false;
        dispatch(logout());
        if (!window.location.pathname.includes('auth')) {
          navigate('/auth/login', { replace: true });
        }
      } else {
        isValid = true;
      }
    });

    return isValid;
  };

  const authCheck = () => {
    if (user && token) {
      navigate('/dashboard', { replace: true });
    }
  };

  useEffect(() => {
    verifyToken().then((isValid) => {
      if (isValid) {
        authCheck();
      }
    });
  }, [user]);

  return <Outlet />;
};

export default GuestGuard;
